import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route } from "react-router-dom";

import PrivateRoute from "./privateRoute";
import App from "./components/app";
import Admin from "./components/admin";
import Dashboard from "./components/dashboard";
import Bio from "./components/bio";

ReactDOM.render(
  <HashRouter>
    <PrivateRoute exact path="/admiral/admin/dash" component={Dashboard} />
    <Route exact path="/admiral/admin" component={Admin} />
    <Route exact path="/bio" component={Bio} />
    <Route exact path="/" component={App} />
  </HashRouter>,
  document.getElementById("root")
);
